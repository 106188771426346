/*iPad*/
@media all and (max-width: 991px)  {
	.hidden-sm {
		display: none !important;
	}
}

@media all and (max-width: 1750px)  {
	.welcomeImg{
		display: none !important;
	}
	.welcomeInner {
	    left: 50%;
	}
}
@media all and (max-width: 1100px)  {
	.MenuContainer,
	.topBar{
		display: none !important;
	}
	.MenuButton{
		@include transition($t1 !important); 
	}
	.homeLink{
		display: inline-block !important;
	}
}
@media all and (max-width: 1517px)  {
	#breadcrumbs {
   	padding: 10px 50px;
	}
}
@media all and (max-width: 1400px)  {
	footer{
		display: block !important;
	}
	.fse{
	    position: relative !important;
	    clear: both;
	    width: 100%;
	    float: left;
	    text-align: center !important;
	    right: auto;
	    display: block;
	    padding-top: 15px;
	}
}
@media all and (max-width: 1250px)  {
	.pageContentInner{
		padding-left: 10px !important;
		padding-right: 10px !important;
	}
	.pageContentSection {
	    padding-left: 30px !important;
	    padding-right: 30px !important;
	}
	.leftImg{
		display: none;
	}
	#Home1 .col-five .center .hover{
	    font-size: 0.8em !important;
	}
	#breadcrumbs {
	    padding: 10px 39px;
	}
	.rightToggle{
		text-align: center;
		font-size: 0.85em;
		padding: 16px;
	}
	.leftToggle{
		text-align: center;
		font-size: 0.85em;
		padding: 16px;
	}

	.pMid20{
		padding: 0px !important;
	}
	.HeadsWelcome {
	    width: 70%;
	    left: -70%;
	}
}
@media all and (max-width: 1100px)  {
	.newsSliderImage{
		display: none !important;
	}
	.NewsSliderContent{
		width: 100% !important;
	}
	.UpcomingNews{
		height: auto !important;
	}

	.UpcomingNews {
		width: 400px;
		right: -400px;
	}
}
@media all and (max-width: 1030px)  {
	*{
		background-attachment: initial !important;
		-webkit-background-attachment: initial !important;
	}
	.parallax{
		@include transform(translateY(0px) !important);
	}
}

/*** Navigation ***/
@media all and (min-width: 1101px)  {
	#MobileNavigation{
		display: none !important;
	}
	.ActiveMenu {
	    overflow: visible !important;
	}
}
@media all and (max-width: 1030px)  {
/*	.MenuContainer,
	.topBar{
		display: none !important;
	}
	.MenuButton{
		@include transition($t1 !important);
	}*/
	.bannerInfo {
	    bottom: 13%;
	}
}
/*** Navigation ***/

@media all and (max-width: 1000px)  {
	.quoteBox{
		display: none !important;
	}
	.bannerInfo .part1 {
	    font-size: 2em !important;
	}

	.bannerInfo .part2 {
	    font-size: 2.5em !important;
	}
	.bannerInfo {
	    line-height: 2em;
	}

	.bannerInfo img {
		width: 17vw;
		min-width: 150px;
		margin-bottom: -6vw;
	}


	.box-1,
	.box-2,
	.box-3{
		width: 33.33%;
	}
	.box-4,
	.box-5{
		width: 50%;
	}
	#Home1 .col-five .center .hover{
	    font-size: 1em !important;
	}
	.box-1,
	.box-2,
	.box-3,
	.box-4{
		width: 50% !important;
	}
	.box-5{
		width: 100% !important;
	}
}
@media all and (max-width: 940px)  {
	.calendarContainer{
		.col-6{
			width: 100% !important;
		}
	}
}
@media all and (max-width: 726px)  {
	.NewsletterItem{
		width: 50% !important;
	}
}
@media all and (max-height: 726px)  {

	.UpcomingNews,
	.UpcomingNews.pageNews{
		@include transform(translate(0px, 0px) !important);
	    top: 54px !important;
	}

	.sideLinks {
		top: 278px !important;
	}

	
}

@media all and (max-width: 800px)  {
	.HomePage.activeWelcome,
	.activeWelcome{
		overflow: hidden !important;
		height: 100vh !important;
		max-height: 100vh !important;
		.leftToggle{opacity: 0;}
	}
	.HeadsWelcome {
		position: fixed;
    	z-index: 102;
	    left: -100%;
	    width: 100%;
	}
	.HomePage{
		.HeadsWelcome {
			position: absolute;
		}
	}
	.HomePage.activeWelcome{
		.HeadsWelcome {
			position: fixed !important;
		}
	}
	.HeadsWelcome .closeMe {
	    left: 10px;
	}
}
@media all and (max-width: 1024px)  {
	#Home1 .col-five{
		@include border-radius(0px !important);
	}
	.pageContentSection{
	    min-height: 0px !important;
	}

	.calendarContainer{
		background: none !important;
		background-color: #fff !important;
		padding: 0px !important;
		.inner{
			@include transform(rotateX(0deg) !important);
			background-color: #fff !important;
		}
	}
	#sidebar{
		padding-left: 30px !important;
		padding-right: 30px !important;
		padding-top: 7px;
	    border-top: 2px solid #eee;
	    border-bottom: 2px solid #eee;
	}
	.hideSideBarToggle{
		top: 10px !important;
		right: 30px !important;
	}
	.calendarContainer .inner .col-6{
		padding: 50px 20px !important;
	}
	.clearMid{
		clear: both;
		width: 100% !important;
	}

	.tacMid{
		text-align: center !important;
	}
	.pageContentInner{
		border-right: 0px !important;
	}
}

@media all and (max-width: 768px)  {
	.grid-item, .grid-sizer { 
		width: 50% !important; 
	}
}

@media all and (max-width: 700px)  {
	.pageTitle{
		font-size: 2em;
		padding-right: 0px !important;
	}
	.pageTitle img{
		display: none !important;
	}
}
@media all and (max-width: 600px)  {
	.calendarSectionTitle {
	    font-size: 2.2em;
	}
	.show-month{
		padding-left: 0px !important;
	}

	#QuotesSliderContainer{
	    font-size: 0.7em;
	}
}
@media all and (max-width: 629px)  {
	#gallery{
		text-align: center;
	}
}
@media all and (max-width: 500px)  {
	.heading-2{
		font-size: 1.3em;
		*{font-size: 1em;color: #303c68;}
	}
	#MobileNavigation .TopLevel {
	    max-width: 83% !important;
	}
	.UpcomingNews .activeNews{
		z-index: 9999 !important;
	}
	.UpcomingNews {
		width: 100%;
		right: -100%;
	}

	.activeNews .HeadsWelcome {
    	z-index: 2;
	}
	.activeNews .rightToggle{
		opacity: 0 !important;
	}

	#MobileNavigation {
		min-width: 0px !important;
		width: 100% !important;
	}

	.calendar-widget tbody * {
	    font-size: 0.6em;
	}
	#HomepageEvents {
	    font-size: 0.7em;
	}

	.box-1,
	.box-2,
	.box-3,
	.box-4,
	.box-5{
		width: 100% !important;
	}
	.bannerInfo .part1 {
	    font-size: 1.6em !important;
	}

	.bannerInfo .part2 {
	    font-size: 2em !important;
	}
	.bannerInfo {
	    line-height: 2em;
	}

	.bannerInfo img {
		width: 80px;
    	min-width: 0px;
	}
	.NewsletterItem{
		width: 100% !important;
	}
}

@media all and (max-width: 450px)  {
	.grid-item, .grid-sizer { 
		width: 100% !important; 
	}
}
@media all and (max-width: 450px)  {

	.tacSmall{
		text-align: center !important;
	}
	.hiddenSmall {
		display: none !important;
	}
}
