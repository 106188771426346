*{
	font-family: $font-stack;
	color: $textDark;
	text-decoration: none !important;
	outline: none !important;
}

body{
	overflow-y: scroll;
	overflow-x: hidden;
	position: relative;
	width: 100%;
	float: left;
background-color: #303c68;
}
html{
	position: relative;
	width: 100%;
	float: left;
}

@font-face {
    font-family: 'GochiHandRegular';
    src: url('../fonts/GochiHandRegular.eot');
    src: url('../fonts/GochiHandRegular.eot') format('embedded-opentype'),
         url('../fonts/GochiHandRegular.woff2') format('woff2'),
         url('../fonts/GochiHandRegular.woff') format('woff'),
         url('../fonts/GochiHandRegular.ttf') format('truetype'),
         url('../fonts/GochiHandRegular.svg#GochiHandRegular') format('svg');
}

@font-face {
    font-family: 'VarelaRegular';
    src: url('../fonts/VarelaRegular.eot');
    src: url('../fonts/VarelaRegular.eot') format('embedded-opentype'),
         url('../fonts/VarelaRegular.woff2') format('woff2'),
         url('../fonts/VarelaRegular.woff') format('woff'),
         url('../fonts/VarelaRegular.ttf') format('truetype'),
         url('../fonts/VarelaRegular.svg#VarelaRegular') format('svg');
}

@font-face {
    font-family: 'LibreBaskervilleItalic';
    src: url('../fonts/LibreBaskervilleItalic.eot');
    src: url('../fonts/LibreBaskervilleItalic.eot') format('embedded-opentype'),
         url('../fonts/LibreBaskervilleItalic.woff2') format('woff2'),
         url('../fonts/LibreBaskervilleItalic.woff') format('woff'),
         url('../fonts/LibreBaskervilleItalic.ttf') format('truetype'),
         url('../fonts/LibreBaskervilleItalic.svg#LibreBaskervilleItalic') format('svg');
}

@font-face {
    font-family: 'MinionProRegular';
    src: url('../fonts/MinionProRegular.eot');
    src: url('../fonts/MinionProRegular.eot') format('embedded-opentype'),
         url('../fonts/MinionProRegular.woff2') format('woff2'),
         url('../fonts/MinionProRegular.woff') format('woff'),
         url('../fonts/MinionProRegular.ttf') format('truetype'),
         url('../fonts/MinionProRegular.svg#MinionProRegular') format('svg');
}


.NewsletterItem{
	display: block;

		width: 33.33%;
		float: left;
	padding: 10px;
	.NewsletterInner{
		display: block;
		position: relative;
		width: 100%;
		@include border-radius(3px);
		@include transition($t1);
		border: 5px solid #fff;
		@include box-shadow(0px 0px 10px 0px #9c9c9c);
		overflow: hidden;
		.newsletterThumb{
			float: left;
			clear: both;
			background-color: #eee;
			width: 100%;
			background-image: url(../images/newsletter.png);
			background-size: 60%;
    		background-repeat: no-repeat;
			background-position: center;
			padding-bottom: 110%;
		}
		a{
			position: absolute;
			top: 0px;
			left: 0px;
			width: 100%;
			height: 100%;
		}
	}
	&:hover{
		.NewsletterInner{
			border: 5px solid #303c68 !important;
		}
	}
	.newsletterTitle{
		padding: 10px;
		font-size: 0.8em;
		float: left;
	    width: 100%;
	    text-align: center;
	    clear: both;
	}
}

.Newsletter{
	width: 33.33%;
    padding-bottom: 33.33%;
    position: relative;
	min-width: 200px;
    min-height: 200px;
    display: inline-block;
    float: none;
}
.NewsLetterInner{
	top: 50%;
	left: 50%;
	width: 90%;
	height: 90%;
	border-radius: 3px;
	overflow: hidden;
	position: absolute; 
	border: 2px solid #d2d2d2;
    background-color: #f9f9f9;
	transition: all 0.35s;
	-o-transition: all 0.35s;
	-ms-transition: all 0.35s;
	-moz-transition: all 0.35s;
	-webkit-transition: all 0.35s;
	transform: translate(-50%, -50%);
	-o-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	-moz-transform: translate(-50%, -50%);
	-webkit-transform: translate(-50%, -50%);
	box-shadow: 0px 0px 20px 0px rgba(0,0,0, 0); 
	-o-box-shadow: 0px 0px 20px 0px rgba(0,0,0, 0); 
	-moz-box-shadow: 0px 0px 20px 0px rgba(0,0,0, 0); 
	-webkit-box-shadow: 0px 0px 20px 0px rgba(0,0,0, 0); 
}



.NewsLetterInner .imgContainer{
	display: inline-block;
	position: absolute;
	left: 50%;
	top: 40%;
	transform: translate(-50%, -50%);
	-o-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	-moz-transform: translate(-50%, -50%);
	-webkit-transform: translate(-50%, -50%);
}
.Newsletter a{
	position: absolute; 
	width: 100%;
	height: 100%;
	z-index: 200;
	left: 0px;
    top: 0px;
}
.Newsletter:hover .NewsLetterInner{
	border: 2px solid #d2d2d2;
    background-color: #f9f9f9;
	box-shadow: 0px 0px 20px 0px rgba(0,0,0, 0.15); 
	-o-box-shadow: 0px 0px 20px 0px rgba(0,0,0, 0.15); 
	-ms-box-shadow: 0px 0px 20px 0px rgba(0,0,0, 0.15); 
	-moz-box-shadow: 0px 0px 20px 0px rgba(0,0,0, 0.15); 
	-webkit-box-shadow: 0px 0px 20px 0px rgba(0,0,0, 0.15); 
}

.Newsletter .downloadInfo{
	position: absolute;
	transform: translateX(-50%);
	-o-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	-moz-transform: translateX(-50%);
	-webkit-transform: translateX(-50%);
	left: 50%;
	color: #777;
	width: 98%;
	text-align: center;
	font-size: 1em;
	bottom: 10%;
	transition: all 0.35s;
	-o-transition: all 0.35s;
	-ms-transition: all 0.35s;
	-moz-transition: all 0.35s;
	-webkit-transition: all 0.35s;
}

.Newsletter:hover .downloadInfo{
	
    color: #e42c25 !important;
}

.NewsletterTitle{
	position: relative;
	padding-bottom: 10px;
	margin-bottom: 30px;
	margin-top: 50px;
	text-align: center;
	font-size: 1.5em;
	color: #303c68 !important;
	*{
		font-weight: lighter;
		color: #303c68 !important;
	}
}
.archives{
	position: relative;
	display: block;
	float: left;
	width: 100%;
	clear: both;
	padding: 0px !important;
	margin: 0px 0px 30px 0px !important;

	.archive{
		position: relative;
		display: block;
		float: left;
		clear: both;
		color: #fff;
		width: 100%;
		margin: 5px 0px;
		cursor: pointer;
		@include transition($t1);
		img{
			position: absolute;
		    right: 10px;
		    top: 50%;
		    max-width: 17px;
			@include transform(translateY(-50%));
		}
		a{
			display: block;
			width: 100%;
			clear: both;
			float: left;
			padding: 8px 30px  8px 15px;
			top: 0px;
			left: 0px;
			width: 100%;
			z-index: 1;
			height: 100%;
		}
		*{
			color: #fff !important;
		}
		
		background-color: #303c68;
		@include border-radius(3px);
		 &:hover{
		    	background-color: #e42c25 !important;
		    }
	}
}


.archive{
	*{
		color: #fff !important;
	}
}

.news-teaser{
	padding: 30px 0px;
	border-bottom: 2px solid #eee;
	display: block;
	float: left;
	width: 100%;

	h3{
		font-family: 'GochiHandRegular';
  		color: #303c68;
  		font-size: 1.5em;
	}
	img{
		@include border-radius(5px);
	}
}

.moreNews{
	margin-top: 30px !important;
}



.noticesBG{
	position: fixed;
	display: none;
	top: 0px;
	left: 0px;
	z-index: 9999;
	height: 100%;
	width: 100%;
	background-color: rgba(0,0,0, 0.75);
}
.noticesContainer{
	position: fixed;
	z-index: 9999;
	top: 50%;
	display: none;
	left: 50%;
	@include transform($center);
	padding: 30px;
	background-color: #e42c25;
	max-width: 100%;
	width: 500px;
}
.NoticeTitle{
	text-transform: uppercase;
	font-weight: bold;
	padding: 0px !important;
    border-bottom: none !important;
    font-size: 1.3em;
}
.readMoreLinkNotices{
    font-size: 0.8em;
    border: 2px solid #fff;
    padding: 3px 5px;
    margin-top: 10px;
    display: inline-block;
}
.fullWidth{
	position: relative;
	clear: both;
	width: 100%;
	float: left;
}
.closeNotices{
	position: absolute;
	top: 10px;
	right: 10px;
	padding-right: 20px;
	color: #fff;
	font-size: 0.8em;
	cursor: pointer;
	z-index: 9999; 

}
.closeNotices:after {
    content: "x";
    position: absolute;
    color: #fff;
    display: block;
    right: 5px;
    top: 49%;
    text-transform: uppercase;
    @include transform(translateY(-50%));
}
.noticesInner{
	max-height: 90vh;
    overflow-y: auto;
    padding: 10px;
	*{
		color: #fff;
	}
	.col-12{
		padding: 20px 0px;
		border-bottom: 2px solid rgba(255,255,255,0.6);
	}

}

.galleryImage{
	display: inline-block;
	width: 33.33%;
	min-width: 180px;
	min-height: 180px;
	position: relative;
	padding-bottom: 33.33%;

	.galleryImageInner{
		position: absolute;
		overflow: hidden;
		width: 95%;
		height: 95%;
		left: 50%;
		top: 50%;
		background-color: #2b2b2b;
		@include transform(translate(-50%,-50%));
		.gallerImageBg{
			@include transition($t1);
			position: absolute;
			width: 100%;
			height: 100%;
			background-size: cover;
			background-positon: center;
			left: 0px;
			top: 0px;
			opacity: 1;
		}
		.gallerImageText{
			opacity: 0;
			text-align: center;
			width: 95%;
			@include transition($t1);
			position: absolute;
			top: 50%;
			left: 50%;
			color: #fff !important;
			@include transform(translate(-50%,-50%) scale(0.5));
		}
	}
	&:hover{
		.galleryImageInner{
			.gallerImageBg{
				opacity: 0.2 !important;
			}
			.gallerImageText{
				@include transform(translate(-50%,-50%) scale(1));
				opacity: 1 !important;
			} 
		}
	}
	a{
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0px;
		left: 0px;
		z-index: 2;
	}
}

/*
|===============================================================
|	Gallery 3
|===============================================================
*/

#bx_slider {
	height: 500px;
	li {
		height: 100%;
		text-align: center;
		img {
			display:inline-block;
			height: 100%;
		}
	}
}
.innerSlideGal{
	padding-bottom: 100%;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	a{
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
	}
}

.GalNext,
.GalPrev{
	cursor: pointer;
	position: absolute;
	z-index: 200;
	top: 50%;
	margin-top: 8px;
	@include transform(translateY(-50%));
	i{
		color: #fff !important;
	}
}
.GalNext{
	left: 0px;
	padding: 20px;
	background-color: rgba(0,0,0,0.9);
}
.GalPrev{
	right: 0px;
	padding: 20px;
	background-color: rgba(0,0,0,0.9);
}

.bx_pages_frame {
	position: relative;
	#bx_pages {
		margin-top: 20px;
		li {
			background-position: center center;
			cursor: pointer;
			height: 200px;
			    opacity: 0.5;
			    @include transition(opacity 0.35s);
			    &:hover{
			   	 opacity: 1 !important;
			    }
		}
	}
	.controls {
		i {
			position: absolute;
			top: 50%;
			color: $mainColor2;
			font-size: 24px;
			margin-top: -12px;
			cursor: pointer;
			@include transition(all 0.5s);
			&:hover {
				opacity: 0.5;
			}
		}
		i.prev {
			left: 0;
		}
		i.next {
			right: 0;
		}
	}
}

/*
|===============================================================
|	Masonry
|===============================================================
*/

.grid {
	position: relative;
	top: 0;
	left: 0;
	width: 100%;
	height: 700px;
	a {
		font-size: 0;
		line-height: 0;
	}
}

.grid-item, .grid-sizer { 
	position: relative;
	width: 25%; 
	float: left;
	padding: 2px;
	cursor: pointer;
	@include transition(all 0.5s);
	img {
		width: 100%;
		height: auto;
	}
}

.grid-item.hoverState {
	opacity: 0.7;
}

.grid-item--width2 { width: 400px; }


.galleryImage{
	display: inline-block;
	width: 33.33%;
	min-width: 180px;
	min-height: 180px;
	position: relative;
	padding-bottom: 33.33%;

	.galleryImageInner{
		position: absolute;
		overflow: hidden;
		width: 95%;
		height: 95%;
		left: 50%;
		top: 50%;
		background-color: #2b2b2b;
		@include transform(translate(-50%,-50%));
		.gallerImageBg{
			@include transition($t1);
			position: absolute;
			width: 100%;
			height: 100%;
			background-size: cover;
			background-positon: center;
			left: 0px;
			top: 0px;
			opacity: 1;
		}
		.gallerImageText{
			opacity: 0;
			text-align: center;
			width: 95%;
			@include transition($t1);
			position: absolute;
			top: 50%;
			left: 50%;
			color: #fff !important;
			@include transform(translate(-50%,-50%) scale(0.5));
		}
	}
	&:hover{
		.galleryImageInner{
			.gallerImageBg{
				opacity: 0.2 !important;
			}
			.gallerImageText{
				@include transform(translate(-50%,-50%) scale(1));
				opacity: 1 !important;
			} 
		}
	}
	a{
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0px;
		left: 0px;
		z-index: 2;
	}
}


/*
|===============================================================
|	Uploads
|===============================================================
*/

.upload{
	position: relative;
	display: block;
	width: 100%;
	max-width: 500px;
	clear: both;
	float: left;
	padding: 10px 45px 12px 30px;
	border: 1px solid #e42c25;
	margin: 5px 0px;
	background-color: transparent;
	@include transition($t1);
	.uploadTitle{
		text-transform: uppercase;
		font-size: 1em;
		font-weight: normal !important;
		color: #e42c25 !important;
	    display: inline-block;
	}
	a{
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
	}
	.fileIcon{


		position: absolute;
		right: 10px;
		top: 50%;
		@include transform(translateY(-50%));



	    background-color: #e42c25;
	    display: inline-block;
	    float: right;
	    width: 25px;
	    height: 30px;
	    @include transition($t1);
	    span{
	    	@include transition($t1);
	    	color: #fff !important;
		    background-color: #102d40;
		    height: 13px;
		    width: 100%;
		    padding-top: 2px;
		    font-size: 7px;
		    text-transform: uppercase;
		    display: block;
		    text-align: center;
		    margin-top: 8px;
	    }
	}

	&:hover{
		background-color: rgba(153, 170, 217, 0.22) !important;
		color: #99aad9 !important;
		.uploadTitle{
			/*color: #000 !important;*/
		}
		/*.fileIcon{
	    	background-color: #000 !important;
			span{
			    color: #000 !important;
				background-color: #009e7f;
		   }
		}*/
	}
}


.upload{
	/*padding: 10px;
	border-bottom: 2px solid #eee;*/
	*{
		vertical-align: middle;
		font-weight: lighter !important;
	}
}


body{
	overflow-y: scroll;
	overflow-x: hidden;
	background-color: #ffffff;
}

img{
	max-width: 100%;
}

.topBanner{
    position: absolute;
    top: -91px;
    width: 100%;
    height: 400px;
    left: 0px;
    z-index: 2;
}

.logo{
	display: block;
	position: absolute;
	z-index: 8000;
/* 	width: 150px; */
	width: 200px;
	left: 80px;
	top: 70px; 
}

.bg{
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	background-position: center;
	background-size: cover;
	background-image: url(../images/bg.jpg);
	/*background-position: top center !important;*/
/* 	background-attachment: fixed;
	-o-background-attachment: fixed;
	-ms-background-attachment: fixed;
	-moz-background-attachment: fixed;
	-webkit-background-attachment: fixed; */
}

.staffProfile {
	.staffProfilePic {
		width:200px;
		height:200px;
		background-size: cover;
		background-position:center center;
		border-radius:20px;
	}
}
.HorizontalStack {
	.staffProfile {
		width:auto;
		margin:0 30px 30px 0;
		.profilePic {
			margin:0 !important;
		}
	}
}

/*** Navigation ***/
#Navigation{
    position: absolute;
    top: 4px;
    width: 100%;
    max-width: 1400px;
    text-align: center;
    z-index: 8002;
    font-size: 0.7em;
    text-transform: uppercase;
    left: 50%;
	@include transform(translateX(-50%));
	*{
		color: #fff;
	}
	.top-Level{display: inline-block;}
	.Level_1{
		position: relative;
		display: table-cell;
		.Level-1-link{
			display: block;
			text-transform: uppercase;
			padding: 10px 20px;
		}
		&:hover{
			.subMenu{
				display: block;
			}
		}
	}
	.subMenu{
		position: absolute;
		display: none;
		width: 440px;
		background-color: rgba(113, 140, 255, 0.73);
	    padding: 20px;
	    @include border-radius(0px 0px 20px 20px);
	    li{
	    	display: block;
		    width: 200px;
		    float: left;
		    text-align: left;
		    a{
		    	display: block;
		    	@include transition($t1);
		    	/* font-size: 0.9em; */
		    	&:hover{
		    		color: #0d1031;
		    	}
		    }
	    }
	}
}

.loading{
	position: fixed;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	z-index: 9999;
	background-color: #303c68;
	img{
		width: 30px;
		position: absolute;
		top: 50%;
		left: 50%;
		@include transform($center);
	}
}

.leftImg{
	position: absolute;
	left: 0px;
	top: 120px;
}

.flexslider .flex-direction-nav {
    position: absolute;
    top: 50%;
    left: 50%;
    height: 30px;
    z-index: 800;
    width: calc(100% - 80px);
    @include transform($center);
}


.pageTitle{
	width: 100%;
	padding-top: 10px;
	display: block;
	float: left;
	position: relative;
	font-size: 3.4em;
	*{
		font-weight: 1em;
	}
	min-height: 120px;
	padding-right: 120px;
	span{
		font-family: 'GochiHandRegular';
		color: #303c68; 
	}
	* {display: inline-block; vertical-align: middle;}
	img{
		position: absolute;
		top: 50%;
		@include transform(translateY(-50%));
		right: 0px;
		max-width: 110px !important; 
	}
}

.pageContentSection{
	padding-left: 25%;
    min-height: 450px;
	padding-right: 20px;
}
.pageContentInner{
	
    min-height: 300px;
	font-size: 1.2em;
	*{
		font-size: 1em;
	}
	ul li,
	ul li p,
	ol li,	
	ol li p,	
	p{
		*{
			color: #303c68; 
		}
		a{
			color: #e42c25 !important;
		}
		a.button{
			color: #fff !important;
		}
	}
	padding-right: 30px;
	border-right: 2px solid #303c68;
}

#Quotes{
	padding: 20px 20px;
    background-color: #f1f1f1;

    .quote{
    	display: inline-block;
    	position: relative;
    	padding: 36px;
		color: #303c68; 
    }
    .QuoteRight{
    	position: absolute;
        bottom: -66px;
    	right: 17px;
    }
    .QuoteLeft{
    	position: absolute;
	    top: -31px;
	    left: 3px;
    }
    .QuoteLeft,
	.QuoteRight{
		opacity: 0.3;
		font-size: 3em;
		color: #303c68;
		display: none;
	}
 
	#QuotesSliderContainer{
		*{ font-family: 'LibreBaskervilleItalic';}
		section{
		    .innerSlide{
			    font-size: 2em;
			    font-style: italic;
			    color: #303c68; 
			    text-align: center;
		    }
		    .SliderName{ 
			    text-align: center;
		    	font-size: 1.4em;
		    	color: #303c68;
		    }
		}
	}
}


/*** Scroll Bar ***/
::-webkit-scrollbar{
	width:10px;
	height:9px;
}
::-webkit-scrollbar-button:start:decrement,
::-webkit-scrollbar-button:end:increment{
	display:block;
	height:0;
	background-color:transparent;
}
::-webkit-scrollbar-track-piece{
	background-color:#eee;
	@include border-radius(0px);
}
::-webkit-scrollbar-thumb{
	background-color: $themeColor;
	@include border-radius(0px);
}
::-webkit-scrollbar-thumb:vertical{
}
::-webkit-scrollbar-thumb:horizontal{
}
::-webkit-scrollbar-thumb:hover{
	background-color: $themeColor;
}
.slideBG{
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	background-image: url(../images/img1.jpg);
	background-size: cover;
	background-position: center;
}
.bg{
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	background-image: url(../images/img1.jpg);
	background-size: cover;
	background-position: center;
}
.flexslider .slides li {
    position: relative;
    height: 100vh;
}
.pageBanner{
	height: 60vh !important;
	.flexslider .slides li {
	    position: relative;
	    height: 60vh;
	}	
}
.quoteBox {
    position: absolute;
    top: 50%;
    left: 150px;
    @include transform(translate(0%, -50%));
    z-index: 2;
    color: #fff;
    font-size: 2em;
    display: block;
    opacity: 1;
    line-height: 1.2em;
    text-transform: uppercase;
    *{
    	color: #fff !important;
    }
}



.leftToggle,
.rightToggle{
	position: absolute;
	background-color: #e42c25;
	color: #fff !important;
    cursor: pointer;
    z-index: 80;
}
.leftToggle{
    top: 50%;
    @include transform(translateY(-50%) translateX(100%));
    right: 0px;
    text-transform: uppercase;
    font-size: 1em;
    font-weight: lighter;
    padding: 10px 20px;
	min-height: 222px;
    border-radius: 0px 15px 15px 0px ;
     span{
		position: absolute;
		color: #fff;
		top: 50%;
		min-width: 222px;
		text-align: center;
		font-size: 1em;
		left: 50%;
    	@include transform(translate(-50%,-50%) rotate(-90deg));
    }
}
.rightToggle{
	height: 100%;
    top: 0px;
    @include transform(translateX(-100%));
    left: 0px;
    text-transform: uppercase;
    font-size: 1em;
    font-weight: lighter;
    padding: 10px 20px;
    border-radius: 15px 0px 0px 15px;
    span{
		position: absolute;
		color: #fff;
		top: 50%;
		text-align: center;
		min-width: 222px;
		font-size: 1em;
		left: 50%;
    	@include transform(translate(-50%,-50%) rotate(90deg));
    }
}


.welcomeInner::-webkit-scrollbar{
	width: 4px;
	height: 9px;
}
.welcomeInner::-webkit-scrollbar-button:start:decrement,
.welcomeInner::-webkit-scrollbar-button:end:increment{
	display:block;
	height:0;
	background-color:transparent;
}
.welcomeInner::-webkit-scrollbar-track-piece{
	background-color:#eee;
	@include border-radius(0px);
}
.welcomeInner::-webkit-scrollbar-thumb{
	background-color: #656571;
	@include border-radius(0px);
}
.welcomeInner::-webkit-scrollbar-thumb:vertical{
}
.welcomeInner::-webkit-scrollbar-thumb:horizontal{
}
.welcomeInner::-webkit-scrollbar-thumb:hover{
	background-color: #656571;
}
.HeadsWelcome{
    z-index: 80;
	@include transition(all 1s);
	position: absolute;
	top: 0px;
	width: 50%;
	height: 100%;
	left: -50%;
	background-color: #fff;
	
	.welcomeImg{
	    position: absolute;
	    bottom: 19px;
	    right: -17px;
	    @include transform(rotate(-62deg));
	    width: 190px;
	}
}
.welcomeInner{
    position: absolute;
    top: 50%;
    left: 44%;
	@include transform($center);
    max-height: 80vh;
    overflow: auto;
    width: 74%;
    z-index: 2;
    padding: 20px;
    background-color: rgba(255,255,255,0.8);
}
.welcomeLogo{
	margin-bottom: 15px;
	width: 160px;
}
.Welcometitle{
	margin-bottom: 15px;
	color: #303c68;
	font-size: 2.3em;
	font-weight: lighter;
	text-transform: uppercase;
}
.welcomeContent{
	color: #303c68;
	*{
		color: #303c68;
	}
	font-size: 0.8em;
}
.pageNews{
    top: 20% !important;
}
.pageSideLinks{
	bottom: auto !important;
	top: 39%;
	.sideLink{
		@include box-shadow(0px 0px 3px 0px rgba(0,0,0,0.3));
	}
}
.pageSideLinks,
.pageNews,
.pageWelcome{
	position: fixed !important;
	.leftToggle {top: 38%;}
	.leftToggle {top: 38%;}
}

#breadcrumbs{
	*{
		font-size: 1em;
	}
	padding: 10px 10px;
	background-color: #303c68;
	color: #fff;
	*{
		color: #fff;
	}
	.here{
		text-transform: uppercase;
	}
}

.UpcomingNews{
    z-index: 80;
	@include transition(all 1s);
	position: absolute;
	top: 50%;
	@include transform(translateY(-50%));
	width: 40%;
	height: 221px;
	
	right: -40%;
	background-color: #fff;
}
.activeWelcome{
	.flex-direction-nav{
		z-index: 20 !important;
	}
	overflow: hidden;
	height: 100vh;
	.HeadsWelcome{
	z-index: 101 !important;
	@include box-shadow(0px 0px 42px 0px rgba(0,0,0,0.2));
		left: 0% !important;
	}
}
.HomePage.activeWelcome{
	overflow-x: hidden !important;
	overflow-y: auto !important;
}
.activeNews{
	.flex-direction-nav{
		z-index: 20 !important;
	}
	.UpcomingNews{
		z-index: 101 !important;
		@include box-shadow(0px 0px 42px 0px rgba(0,0,0,0.2));
		right: 0% !important;
	}
}

.bannerInfo{
	line-height: 3.5vw;
	width: 80%;
	position: absolute;
	bottom: 60px;
	left: 50%;
	text-align: center;
	@include transform( translateY(20px) translateX(-50%) translateX(0%));
	img{
	    margin-bottom: -100px;
	}
	.part1,
	.part2{
		font-family: 'GochiHandRegular';
		color: #fff !important;
	}
	.part1{
		font-size: 3vw;
	}
	.part2{
	    font-size: 5vw;
	}
}

/*** SideLinks ***/
.sideLinks{
    position: absolute;
    z-index: 100;
    right: 0px;
    bottom: 9%;
}
.sideLink{
	background-color: #fff;
	clear: both;
	margin: 3px 0px;
	@include border-radius(30px 0px 0px 30px);
	display: block;
	float: right;
	padding: 5px 0px 5px 40px;
	@include transition($t1);
	position: relative;

	.sideLinkLeft{
		text-align: center;
		width: 27px;
		position: absolute;
		left: 10px;
		top: 50%;
		display: block;
		@include transform(translateY(-50%));
		display: inline-block;
		
		img{
			display: inline-block;
			height: 20px;
		}
	}
	.sideLinkRight{
		display: inline-block;
		@include transition($t1);
		overflow: hidden;
		color: #fff;
		white-space: nowrap;
		font-weight: lighter;
	}
	&:hover{
		padding: 5px 20px 5px 50px;
		background-color: #e42c25  !important;
	}
	a{
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
		display: block;
		z-index: 1;
	}
}
/*** SideLinks ***/
#NewssSliderContainerOuter{
	padding-right: 50px;
}
.newsSliderImage{
	height: 221px;
	overflow: hidden;
	.newsSliderImageInner{
		position: absolute;
		top: 50%;
		left: 50%;
		height: 105%;
		width: 105%;
		@include transform($center);
		background-size: cover;
		background-position: center;
		background-image: url(../images/001.jpg);
	}
}
.NewsSliderContent {
    padding: 40px 20px;
}
.closeMe{
	position: absolute;
    top: 10px;
    right: 10px;
    color: #e42c25;
    font-size: 0.7em;
    text-transform: uppercase;
    cursor: pointer;
    z-index: 3;
}
.NewsSliderTitle{
	color: #303c68;
	font-size: 1.3em;
	margin-bottom: 10px;
}
.NewsSliderText{
	color: #303c68;
}
.NewsSliderReadMore{
	padding-top: 10px;
	a{
		font-size: 0.6em;
		text-align: center;
		display: inline-block;
		padding: 4px 13px;
		color: #fff;
		text-transform: uppercase;
		@include border-radius(3px);
		background-color: #303c68;
	}
}
#NewsPrev,
#NewsNext{
	position: absolute;
	@include border-radius(50%);
	color: #fff !important;
	*{color: #fff !important;}
	text-align: center;
	height: 30px;
	    line-height: 1.9em;
	right: 5px;
	width: 30px;
	background-color: #e42c25;
}
#NewsPrev{
    top: 30px;
    @include transform(rotate(-90deg));
}
#NewsNext{
    bottom: 30px;
    @include transform(rotate(-90deg));
}
/*** Menu ***/
#Menu{
	z-index: 8880;
	right: 108px;
	position: fixed;
	top: 0px;
	display: inline-block;
	text-align: right;
	*{
		color: #fff;
		font-weight: lighter;
	}
	.MenuButton{
		display: inline-block;
		position: absolute;
		z-index: 2;
		cursor: pointer;
		background-color: $themeColor;
	    right: 0px;
	    padding: 14px 12px 7px 12px;
	    height: 50px;
	    cursor: pointer;
	    opacity: 1;
	    top: 0px;
	    text-transform: uppercase;
	}
	.MenuContainer{
		position: relative;
		z-index: -9999;
		margin-top: -600px;
		.LevelOne{
			height: 50px;
			display: inline-block;
			text-align: center;
			position: relative;
			a{
				padding: 10px 20px;
			    font-size: 1em;
			    line-height: 3em;
			}
		}
	}
	.SubMenu{
		min-height: 150px;
		@include box-shadow(-2px 13px 40px 0px rgba(47, 47, 47, 0.33));
		/* width: 250px; */
		width: 400px;
		height: 0px;
		overflow: hidden;
		display: block;
		z-index: -9999;
    	left: 50%;
    	text-align: left;
		position: absolute;
		background-color: #e42c25;
	    border-radius: 5px;
	    padding: 3px 10px 6px 10px;
	    margin-top: 16px;
	    opacity: 0;
	    transition: transform 0.35s 0.120s, opacity 0.35s 0.120s;
	    -o-transition: transform 0.35s 0.120s, opacity 0.35s 0.120s;
	    -ms-transition: transform 0.35s 0.120s, opacity 0.35s 0.120s;
	    -moz-transition: transform 0.35s 0.120s, opacity 0.35s 0.120s;
	    -webkit-transition: transform 0.35s 0.120s, opacity 0.35s 0.120s;
	    @include transform(translateY(20px) translateX(-50%));

	    a{
		    display: inline-block;
		    padding: 3px 10px !important;
		    margin: 3px 0px;
		    /*font-size: 0.9em;*/
		    line-height: 1.2em !important;
		    width: 100%;
			@include border-radius(3px);
			background-color: transparent;
	    	@include transition($t1);
			&:hover{
    			color: #303c68 !important;
			}
		}
		.submenuImg{
		    position: absolute;
		    height: 160px;
		    top: -6px;
		    left: -149px;
		    @include transform( rotate(-65deg));
		}
	}

	.active .SubMenu{
		opacity: 1 !important;
	 	@include transform(translateY(0px) translateX(-50%) !important);
	}

	.SubMenu:before {
	    content: '';
	    display: block;
	    position: absolute;
	    width: 100%;
	    height: 17px;
	    left: 0px;
	    top: -14px;
	    z-index: 2;
	}
	.SubMenu:after {
	    content: '';
	    display: block;
	    position: absolute;
	    border: 10px solid transparent;
	    top: -20px;
	    left: 50%;
	    margin-left: -10px;
	    border-bottom: 10px solid #e42c25;
	    border-left: 10px solid #e42c25;
	}
	
	.menuItem-1,
	.menuItem-2{
		.SubMenu{
			left: 0%;
			@include transform(translateY(20px) translateX(0%) scale(0.98));
		}
		.SubMenu:after {
		    left: 50px !important;
		}
	}
	.menuItem-Last{
		.SubMenu{
			left: auto;
			right: 0%;
			@include transform(translateY(20px) translateX(0%) scale(0.98));
		}

		.SubMenu:after {
			left: auto !important;
		    right: 50px !important;
		}
	}
	.active .SubMenu{
	opacity: 1 !important;
		@include transform(translateY(0px) translateX(-50%) scale(1) !important);
	}
	.menuItem-1.active .SubMenu,
	.menuItem-2.active .SubMenu{
		opacity: 1 !important;
		@include transform(translateY(0px) translateX(0%) scale(1) !important);
	}
	.menuItem-Last.active .SubMenu{
		opacity: 1 !important;
		left: auto;
		right: 0%;
		@include transform(translateY(0px) translateX(0%) scale(1) !important);	
	}

}





.LevelOne a:after {
    content: '';
    position: absolute;
    height: 15px;
    width: 1px;
    background-color: #2f3b67;
    top: 49%;
    right: 0px;
    display: none !important;
    @include transform(translateY(-50%));
}
.LevelOne a{
	@include transition($t1);
}
.LevelOne.active a.topMenuLink{
	color: #fff !important;
}
.LevelOne:last-of-type a:after{
	display: none !important;
}
.menuGroup{
	opacity: 0;
	margin-right: -20px;
}
.MainMenuClose{
	display: inline-block;
}

.topBar{
	top: 0px;
	height: 50px;
	width: 0%;
	right: 108px;
	z-index: 8000;
	position: fixed;
	background-color: $themeColor;
}
.ActiveMenu{
	.topBar{
		width: 100% !important;
	/*	opacity: 0;*/
	}
	.menuGroup{
		opacity: 1 !important;
		margin-right: 0px !important;
	}
	.MenuButton{
		top: -50px !important;
		opacity: 0 !important;
	}
	.MenuContainer{
		z-index: 1 !important;
		margin-top: 0px !important;
	}
}
/*** Menu ***/
.CenterItems{
	display: flex;
	align-items: center;
	justify-content: center;
} 
.flex{
	display: flex;
}
/*** Link Boxes ***/
#Home1{
	.col-five{
		float: left;
		.center{
			padding-bottom: 100%;
			overflow: hidden;
			a{position: absolute; top: 0px;left: 0px;width: 100%; height: 100%;}
			.bg{
				position:absolute;
				left: 0px;
				width: 100%;
				height: 100%;
				top: 0px;
				background-image: url(../images/001.jpg);
				background-size: cover;
				background-position: center;
				@include transform(scale(1));
				@include transition($t1);
				
			}
			.bg2{
				position: absolute;
				top: 0px;
				left: 0px;
				width: 100%;
				height: 100%;
				opacity: 0.5;
				@include transition($t1);
			}
			.bg2-1{ background-color: #1370f9;}
			.bg2-2{ background-color: #0931b8;}
			.bg2-3{ background-color: #5cb0db;}
			.bg2-4{ background-color: #03199b;}
			.bg2-0{ background-color: #0f50f8;}

			.bga1{animation: zoom1 25s linear 0s infinite alternate;}
			.bga2{animation: zoom2 25s linear 0s infinite alternate;}
			.bga3{animation: zoom3 25s linear 0s infinite alternate;}
			.bga4{animation: zoom4 25s linear 0s infinite alternate;}
			.bga0{animation: zoom5 25s linear 0s infinite alternate;}

			.bg-1{ background-color: #1370f9;}
			.bg-2{ background-color: #0931b8;}
			.bg-3{ background-color: #5cb0db;}
			.bg-4{ background-color: #03199b;}
			.bg-0{ background-color: #0f50f8;}
			.hover{
				position: absolute;
				bottom: 0px;
				text-align: center;
				left: 0px;
				width: 100%;
				min-height: 0%;
				padding: 20px 20px;
				@include transition($t1);
				*{color: #fff;vertical-align: middle;display: inline-block;font-weight: lighter;font-size: 1.2em;}
				img{
					width: 30px;
				}
			}

			.hoverIcon{
				position: absolute;
				top: -50px;
				left: 50%; 
				width: 60px;
				height: 60px;
				@include transform(translateY(-50%) translateX(-50%));
				img{
					width: 50px;
					position: absolute;
					top: 50%;
					left: 50%;
					@include transform($center);
				}
			}
			.hoverContent{
				display: inline-block;
				position: relative;
			}
			&:hover{
				.bg2{
					opacity: 0.3 !important;
				}
				.bg{
					@include transform(scale(1.1));
				}
				.hover{
					min-height: 30%;
				}
			}
		}
	}
}

@keyframes zoom1 {
    from {
    	@include transform(scale(1))
    }
    to {
    	@include transform(scale(1.5));
    }
}

@keyframes zoom2 {
    from {
    	@include transform(scale(1.5))
    }
    to {
    	@include transform(scale(1));
    }
}

@keyframes zoom3 {
    from {
    	@include transform(scale(1))
    }
    to {
    	@include transform(scale(1.5));
    }
}

@keyframes zoom4 {
    from {
    	@include transform(scale(1.5))
    }
    to {
    	@include transform(scale(1));
    }
}

@keyframes zoom5 {
    from {
    	@include transform(scale(1))
    }
    to {
    	@include transform(scale(1.5));
    }
}

#HomeCalendar{
	background-image: url(../images/535.jpg);
	background-size: cover;
	background-position: center;
	background-attachment: fixed;
	-webkit-background-attachment: fixed;
}
.calendarContainer{
	display: flex;
	min-height: 100vh;
	padding: 100px 20px;
	perspective: 3000px;
	-o-perspective: 3000px;
	-ms-perspective: 3000px;
	-webkit-perspective: 3000px;
	-moz-perspective: 3000px;
    transform-style: preserve-3d;
    -o-transform-style: preserve-3d;
    -ms-transform-style: preserve-3d;
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
	.inner{
		@include transition(all 1s 1.5s);
		background-color: rgba(255,255,255,0.9);
		opacity: 0;
		@include transform(rotateX(40deg));
		.col-6{
			padding: 50px;
		}
	}
}

.calendar-widget-table table td, .calendar-widget table th {
    border: none !important;
}
.calendar-widget-table tbody .calendar-header td{
	color: #e42c25;	
    font-size: 1.3em;
    padding: 20px 0px;
	    text-transform: uppercase;
	background-color: transparent;
}

.calendar-widget-table thead th {
    background-color: transparent !important;
}
#HomeCalendar.ActiveIn{
	.inner{
		opacity: 1 !important;
		@include transform(rotateX(0deg) !important);
	}
}

.calendar-widget-table {
    margin: 0;
    width: 100%;
}
.calendar-widget-table tbody td {
	@include transition($t1);
    background-color: transparent;
    padding: 20px 0px;
    text-align: center !important;
    width: 13%;
    font-size: 1em;
}

.calendar-widget-table tbody .calendar-day {
	color: #303c68 !important;
    font-size: 1.4em;
}

.calendar-widget-table .show-month{
    color: #303c68 !important;
    font-weight: lighter;
    padding-left: 16px;
    position: absolute;
}
.calendar-widget-table tbody .hasEvent {
    background-image: url(../images/dot.png);
    background-repeat: no-repeat;
    background-position: 90% 10%;
    color: #e42c25 !important;
}

.calendar-widget-table tbody .today {
    color: #fff !important;
    background-color: #e42c25;
}
.calendar-widget-table tbody .calendar-day:hover, .calendar-widget tbody .show-week:hover, .calendar-widget tbody .selected {
    background-color: rgba(228, 44, 37, 0.16);
}

.calendar-widget-table tbody .today.hasEvent {
    background-image: url(../images/dot2.png);
    background-repeat: no-repeat;
    background-position: 90% 10%;
}
.calendar-widget-table tbody .out-of-month {
    background-color: transparent;
    color: #999 !important;
}

.calendar-widget-table thead tr {
    padding-bottom: 55px;
    display: block;
    width: 100%;
}

#allEvents,
#HomepageEvents{
	
    margin-top: 50px;

	li:first-of-type{
		border-top: 2px solid #e42c25;
	}
	li{
	    margin: 0px;
		padding: 10px 20px 10px 0px;
	    clear: both;
	    width: 100%;
	    float: left;
	    display: block;
	    border-bottom: 2px solid #e42c25;
	}
	.toggleContent{
		display: none;
	}
	.toggleBtn{
		font-size: 1.4em;
		cursor: pointer;
		&:hover{
			.eventTitle{
				color: #e42c25 !important;
			}
		}
	}
	.url{
	    display: inline-block;
	    padding: 5px 20px;
	    color: #fff;
	    font-size: 0.75em;
	    border-radius: 3px;
	    background-color: #303c68;
		@include transition($t1);
		&:hover{
	    	background-color: #e42c25 !important;
		}
	}
	.eventTitle{
		display: block;
		@include transition($t1);

	}

	.calday{
		sup{
			display: none !important;
		}
		*{
			color: #e42c25;
		}
	}
	
}
.calendarSectionTitle{
	font-size: 3.2em;
	*{
		font-size: 1em;
	}
	span{
		display: inline-block;
		padding-left: 15px;
		font-family: 'GochiHandRegular';
		color: #303c68;
	    
	}
} 
.LinkboxPadding{
/*	padding: 0px 5px 0px 5px;*/
	z-index: 7;
}
.HomePage #Home1{
/*	padding: 20px 0px;*/
	.LinkboxPadding{
		/*padding: 5px 5px 5px 5px !important;*/
	}
}
#Home1{
	background-color: #303c68;
	.col-five{
		overflow: hidden;
		@include border-radius(1000%);
		opacity: 0;
		@include transform(translateY(50px) scale(0.2));
		span{
		    padding-left: 40px;				
		    text-align: center;
			font-family: 'GochiHandRegular';
		    width: 100%;
		}
		img{
			width: 50px;
			position: absolute;
			top: 50%;
			@include transform(translateY(-50%));
			left: 0px;

		}
	}
}

#Home1.ActiveIn .col-five{
	opacity: 1 !important;
	@include border-radius(0%);
	@include transform(translateY(0px) scale(1) !important);
}
/*** Link Boxes ***/
/*** Top Buttons ***/

.TranslateBG{
	display: none;
	position: fixed;
	top: 0px;
	z-index: 9999;
	left: 0px;
	width: 100%;
	height: 100%;
	background-color: rgba(0,0,0, 0.7);
}
#google_translate_element{
	display: none;
	position: fixed; 
	z-index: 9999;
	top: 50%;
	left: 50%;
	@include transform($center);
}

#SearchResults{ 
	padding: 0px;
	margin: 0px;
	display: block;
	float: left;
	width: 100%;
	font-size: 0.8em;
	li{
	padding: 30px 0px;
	border-bottom: 2px solid #eee;
	margin: 0px;
	display: block;
	float: left;
	width: 100%;
		list-style: none !important;
	}
}

.topLink{
	position: fixed;
    top: 0px;
    height: 50px;
    width: 50px;
    z-index: 9999;
    i{
    	color: #fff;
    	position: absolute;
    	top: 50%;
    	left: 50%;
    	@include transform($center);
    }
    img{
    	position: absolute;
    	top: 50%;
    	left: 50%;
    	@include transform($center);
    }
}
.searchToggle{
	right: 54px;
    background-color: #2f3b67;
}
.translate {
    background-color: #2f3b67;
    right: 0px;
    img{
    	max-width: 24px;
	    position: absolute;
    	top: 50%;
    	left: 50%;
    	@include transform($center);
    }
}
.searchToggleButton{
	position: absolute;
	left: 50%;
	top: 7px;
	@include transform(translateX(-50%));
}

/*** Top Buttons ***/


.calendar-widget-table tbody .today {
    color: #c087bc;
}
.calendar-widget-table thead th {
        text-align: left;
}



.calendar-widget-table{
	i{
		color: #fff !important;
	}
	
	.next{
	    position: absolute;
	    right: 70px;
	    background-color: #95958c;
	    width: 30px;
	    padding: 0px;
	    height: 30px;
	    border-radius: 50%;
	    text-align: center;
	}
	.prev{
	    position: absolute;
	    right: 110px;
	    background-color: #95958c;
	    width: 30px;
	    padding: 0px;
	    height: 30px;
	    border-radius: 50%;
	    text-align: center;
	}
	.next,
	.prev{
		@include transition($t1);
		&:hover{
	   	 background-color: #303c68 !important;
		}
		i{
		    position: absolute;
		    top: 50%;
		    font-size: 1.3em;
		    left: 50%;
		    @include transform($center);
		}
	}

	.prev i{
		top: 46% !important;
	    left: 47% !important;
	}
	.next i{
	    left: 58% !important;
	    top: 46% !important;
	}
}

.CalendarFilter{
	padding: 10px;
}

/* .CalendarFilter{
	span{
		display: inline-block;
		padding: 5px 0px;
		margin: 0px 5px;
		cursor: pointer;
	}
} */
.CalendarEvent #allEvents ul li,
.Calendar #allEvents ul li{
/* 	display: none; */
}
/* .CalendarEvent.All #allEvents ul li,
.Calendar.All #allEvents ul li{
	display: block;
} */
.CalendarFilter span{
	cursor: pointer;
	display: inline-block;
	padding: 5px;
	border-bottom: 2px solid transparent;
	margin-bottom: 10px;
}
.CalendarFilter span.active{
	border-bottom: 2px solid $themeColor !important;
	color: $themeColor !important;
}
.noEvents{
	display: none;
	padding: 20px 10px;
	color: #666;
	@include border-radius(3px);
    background-color: #eee;
}


/* Slider Styling */
#SliderSectionContainer{
	margin-bottom: 50px;
}
.SliderTitle {
    font-size: 1.5em;
    color: #EE363C;
    text-align: center;
    padding: 20px 0px 30px 0px;
}
.bx-controls{
	text-align: center;
}
.bx-pager-item{
	margin: 5px;
	width: 10px;
	height: 10px;
	opacity: 0.5;
	background-color: #2b2b2b;
	transition: all 0.35s;
	-o-transition: all 0.35s;
	-ms-transition: all 0.35s;
	-moz-transition: all 0.35s;
	-webkit-transition: all 0.35s;
	border-radius: 50%;
	-o-border-radius: 50%;
	-ms-border-radius: 50%;
	-moz-border-radius: 50%;
	-webkit-border-radius: 50%;
	display: inline-block;
}
.bx-pager-item:hover{
	opacity: 1 !important;
}
.bx-pager-item a{
	color: transparent;
}
/* Slider Styling */

/*** Video Page ***/
.videoHeader{
	height: 120px;
	background-color: #000;
}
.fullScreenBlackout{
	display: none;
	position: fixed;
	top: 0px;
	left: 0px;
	width: 100%;
	background-color: #000;
	height: 100%;
	z-index: 9998;
}
.videoContainer{
	padding-bottom: 25%;
	overflow: hidden;
}
.video_background{
	width: 100%;
	position: absolute;
	top: 50%;
	left: 50%;
	@include transform($center);
}

.active.video_background{
    position: fixed;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    z-index: 9999;
}
.active{
	.closeVideo{
		display: block !important;
	}
}

.videoBlackout{
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	background-color: rgba(0,0,0, 0.4);
}

.closeVideo{
	display: none;
	position: fixed;
	top: 10px;
	right: 10px;
	z-index: 9999;
	cursor: pointer;
	color: #fff;
	font-size: 1.8em;
	i{color: #fff;}
}

.watchFull{
	cursor: pointer;
	position: absolute;
	color: #fff;
	top: 50%;
	left: 50%;
	z-index: 5;
	@include transform($center);
}
.videoWrapper .videoContainer:nth-child(odd):last-of-type{
	padding-bottom: 50% !important;
	width: 100% !important;
}
@media all and (max-width: 860px)  {
	.videoContainer{
		padding-bottom: 50%;
		width: 100%;
	}
}
/*** Video Page ***/

.db{
	display: block;
}

fieldset{
	border: none;
}

/*** Search Form ***/

.SearchContainer{
    display: none;
    position: fixed;
    top: 7px;
    background-color: #2f3b67;
    padding: 7px;
    right: 54px;
    z-index: 9999;
	@include transform(translateY(100%))

	#Search{
		display: block;
		float: left;
	}

    .middleColumn,
    button,
    #SearchForm_SearchForm_Search_Holder{
    	float: left;
    	display: block;
    }

    button{
	    background-color: rgb(141, 164, 68);
	    border: none !important;
	    color: #fff !important;
	    padding: 5px 10px;


	    i{
	    	color: #fff !important;
	    }
	    float: left;
    	display: block;
    }
    input[type=submit]{
        display: block;
	    border: none !important;
	    font-size: 1em;
	    text-transform: uppercase;
	    padding: 5px;
	    color: #fff !important;
	    font-style: italic;
	    background-color: #17aae4;
    }
    input[type=text]{
	    border: 0px;
	    padding: 5px 10px;
	    color: #5f5e6d;
	    float: left;
    	display: block;
    }
}
/*#SearchForm_SearchForm_Search{
	@include transition(all 400ms ease-in-out 0ms);
	background-color: rgba(200,200,200,.4);
	@include border-radius(50% 0% 0% 50%);
   	font-style: italic;
	position: absolute;
	padding: 0px 0px;
	z-index: 8888;
	height: 35px;
    right: 48px;
	color: #fff;
	border: 0px;
	width: 0px;
}

.activeSearch{
	border-radius: 0% !important;
	width: 150px !important;
 	padding: 0px 25px 0px 20px !important;
}

#SearchForm_SearchForm_action_results{
	color: #ddd;
	@include transition($t1);
	@include appearance(none);
    outline: 0!important;
    position: relative;
    z-index: 9980;
    font-family: WebSymbols;
    text-decoration: none!important;
    display: inline-block;
    text-align: center;
    cursor: pointer;
    border: none!important;
    margin-left: 0;
    font-size: 2em;
    background-color: transparent;
    &:hover{
    	color: #fff;
    }
}
*/
/*** Smooth Scrolling ***/
.smooth {
    top: 0;
    left: 0;
    right: 0;
    position: fixed;
    background-color: $mainColor;
    @include perspective(1000);
    @include transform-origin(0 0);
	@include box-sizing(border-box);
    @include backface-visibility(hidden);
    @include transform(translate3d(0px, 0px, 0px));
    @include transition(all .5s cubic-bezier(0.2, .84, .5, 1));
}

.wrapper{
	position: relative;
	float: left;
	width: 100%;
	background-color: $mainColor;
}

.SmoothScroll{
	 .parallax1 {
		@include perspective(1000);
	    @include transform-origin(0 0);
		@include box-sizing(border-box);
	    @include backface-visibility(hidden);
	    @include transform(translate3d(0px, 0px, 0px));
	    @include transition(all .5s cubic-bezier(0.2, .84, .5, 1));
	}
}

.StandardScroll{
	.smooth{
		 position: relative;
	}
}

/*** Loading Bar ***/
.pace {
  -webkit-pointer-events: none;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  z-index: 2000;
  position: fixed;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 5px;
  width: 200px;
  z-index: 9999;
  background: #fff;
  border: 1px solid #29d;
  overflow: hidden;
}

.pace .pace-progress {
	@include box-sizing(border-box);
	@include transform(translate3d(0, 0, 0));
	max-width: 200px;
	position: fixed;
	z-index: 2000;
	display: block;
	position: absolute;
	top: 0;
	right: 100%;
	height: 100%;
	width: 100%;
	background: #29d;
}

.pace.pace-inactive {
  display: none;
}

/*** Page Loading ***/

.pace-running{
	overflow: hidden;
}

/*** Page Loading Complete ***/
.pace-done{
	.Loading{
		height: 0%;
		opacity: 0;
	}
}

/*** HomePage ***/
.banner{
	position: relative;
	overflow: hidden;
	float: left;
	width: 100%;
	height: 100vh;
	@include background-cover(url("../images/236.jpg"));

	.darken{
		@include stretchCover(rgba(43,43,43, 0.1), absolute, 1)
	}

	.bg{
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0px;
		left: 0px;
		@include background-cover(url("../images/236.jpg"));
	}
}

#home1{
	padding: 100px 0px;
}

.Group1{
	opacity: 0;
}



.AnimatePage{
	.Group1{
		opacity: 1 !important;
	}
	.bannerInfo{
		@include transform(translateY(0px) translateX(-50%) !important);
	}
}




/*** Page ***/
.Page{
	.banner{
		height: 60vh;
	}
}
/*** Footer ***/
footer{
	overflow: hidden;
	border-bottom: 10px solid #e42c25;
	background-color: #303c68;
	padding: 30px 0px;
	color: #fff;
	font-size: 0.8em;

	.starsbottom{
		position: absolute;
	    top: -30px;
	    left: 0px;
	    width: 180px;
	}
	.footerAddress{
		font-size: 1.25em;
	}
	*{
		font-weight: lighter;
		font-size: 1em;
		color: #fff;
	}
	.fse{
		font-size: 0.8em;
		position: absolute;
		bottom: 0px;
		right: 20px;
		text-transform: uppercase;
		*{font-size: 1em;}
	}
}

#content{
	input[type=text],
	input[type=email],
	input[type=password],
	textarea{
	    width: 100%;
	    padding: 10px 20px;
	    background-color: #eee;
	    border: none !important;
	    margin-bottom: 10px;
	    margin-top: 5px;
	    width: 100%;
	 	max-width: 400px;
    	font-weight: lighter;
	}

	label{
		font-weight: lighter;
	}

	input[type=submit]{
			padding: 10px 20px !important;
		    display: block;
		    width: 100%;
		    border: none !important;
		    color: #fff !important;
	    	max-width: 400px;
		    font-weight: lighter;
		    margin-top: 10px;
		    @include transition($t1);
		    @include border-radius(3px);
		    background-color: #303c68;
			max-width: none !important;
		    width: auto !important;
		    display: inline-block;

			&:hover{
		    	background-color: #e42c25 !important;
		    }
	}
	#ForgotPassword{
		margin-top: 20px;
		a{
			font-weight: lighter;
			color: #1d1e3c;
		}
	}
}
.readMoreLink,
.button{
			padding: 10px 20px !important;
		    display: inline-block;
		    text-align: center;
		    color: #fff !important;
		    font-size: 0.7em !important;
		    cursor: pointer;
		    *{
		    	font-size: 1em;
		    	color: #fff !important;
		    }
		    border: none !important;
		    color: #fff !important;
		    font-weight: lighter;
		    margin-top: 10px;
		    @include transition($t1);
		    @include border-radius(3px);
		   	background-color: #303c68;
			&:hover{
		    	background-color: #e42c25 !important;
		    }
	}

#gmap_canvas{
	height: 500px;
}

.dib{
	display: inline-block;
}

.ContactForm{
	#content{
		input[type=submit],
		input[type=text],
		input[type=email],
		input[type=password],
		textarea{
			max-width: 100% !important;
		}
	}
}

.bgWhite{
	background-color: #fff !important;
}
.noBorder{
	border: none !important;
	.pageContentInner{
		border: none !important;
	}
}
.mainContentArea{
	opacity:0;
	
}
.Loading{
	overflow: hidden;
	height: 100vh !important;
}
.pace-done{
	.mainContentArea{
		opacity:1 !important;
	}
}
.pace{
	display: none !important;
}

strong{
	font-weight: bold !important;
	color: #303c68;
}


.staffProfile {
	.staffProfilePic {
		width:200px;
		height:200px;
		background-size: cover;
		background-position:center center;
		border-radius:20px;
	}
}
.HorizontalStack {
	.staffProfile {
		width:auto;
		margin:0 30px 30px 0;
		.profilePic {
			margin:0 !important;
		}
	}
}

/*
|===============================================================
|	Staff Page Templates
|===============================================================
*/

.StaffPage {
	.staffProfile {
		display:inline-block;
		width:100%;
		margin:30px;
		.profilePic {
			margin:0 30px 0 0;
			float:left;
			display:inline-block;
		}
		.bio {
			min-width:50%;

			p {
				display:block;
			}
		}
	}
}

.StaffPageStack {
	.staffProfile {
		text-align:center;
		margin-bottom:60px;
		.bio {
			p {
				text-align:justify;
				width:100%;
				max-width:800px;
				margin:0 auto 20px auto;
			}
		}
	}
}

.StaffPageNoPic {
	.staffProfile {
		text-align:center;
		margin-bottom:60px;
		.bio {
			p {
				text-align:justify;
				width:100%;
				max-width:800px;
				margin:0 auto 20px auto;
			}
		}
	}
}

.bio{
	h4{
		font-weight: normal;
    	font-size: 1.2em;
	}

	h3{
		font-size: 2em;
		font-family: 'GochiHandRegular';
   		color: #303c68;
	}
}

 .HorizontalStack .staffProfile {
    width: auto;
    margin: 0 30px 30px 0;
}

.VerticalStack{
	    margin: 0px ;
	  
	    .staffProfile {
	    	margin: 0px ;
			padding: 30px 0px;
			border-bottom: 2px solid #eee;
		}
}
.NameDepOnly{
	    margin: 0px ;
	  
	    .staffProfile {
	    	margin: 0px ;
			padding: 30px 0px;
			border-bottom: 2px solid #eee;
		}
}
.HorizontalStack{
	.profilePic{
		padding-bottom: 20px;
	}
}

.profilePic{
	padding-bottom: 20px;
    padding-top: 13px;
}
/*** Navigation ***/

.fancybox-overlay {
    z-index: 9999;
}

.vacancy{
	padding: 30px 0px;
	border-bottom: 2px solid #eee;
	display: block;
	float: left;
	width: 100%;
}
#SearchResults h2{
	font-size: 1.5em;
	*{font-size: 1em !important;}
}
.heading-2{
	font-size: 1.5em;
	*{font-size: 1em;color: #303c68;}
}

.CalendarPage,
.CalendarEvent{
	.calendar-widget-table .next {
    	right: 24px;
	}
	.calendar-widget-table .prev {
	    right: 65px;
	}
}

.SideLinksImage{    
	height: 20px;
    width: 20px;
    margin-top: 2px;
	display: inline-block;
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
}

.contentPage{
	a{
		word-break: break-all;
	}
	td{
		border: 1px solid #eee;
	}
}

.calendar-widget td{
	border: none;
}

.dib{
	a{
		font-size: 1em !important;
	}
}

.HomePage.activeWelcome{
	height: 100vh !important;
	overflow: hidden !important;
	.HeadsWelcome{
		    position: fixed !important;
	}
}

.homeLink{
	display: none;
	position: fixed;
	z-index: 4;
	background-color: #303c68;
	color: #fff;
	top: 0px;
	font-weight: lighter;
    font-size: 1em;
	padding: 13px 12px 13px 12px;
	left: 10px;
	text-transform: uppercase;
}

